// Directory.js
import React, { useState } from "react";
import { FaEdit, FaUserCheck, FaUserTimes } from "react-icons/fa";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import EEUU from "../Assets/Images/EEUU.png";
import Peru from "../Assets/Images/Peru.png";
import Venezuela from "../Assets/Images/Venezuela.png";
import Panama from "../Assets/Images/Panama.png";
import Ecuador from "../Assets/Images/Ecuador.png";
import Colombia from "../Assets/Images/Colombia.png";
import Chile from "../Assets/Images/Chile.png";

const banksByCountry = {
    Venezuela: [
      "Banco de Venezuela",
      "Banco Provincial",
      "Banco Mercantil",
    ],
    Chile: [
      "Banco de Chile",
      "Banco Santander",
      "Banco BCI",
    ],
    Colombia: [
      "Banco de Bogotá",
      "Bancolombia",
      "Davivienda",
    ],
    Ecuador: [
      "Banco Pichincha",
      "Banco del Austro",
      "Banco de Guayaquil",
    ],
    Panamá: [
      "Banco General",
      "Banistmo",
      "Global Bank",
    ],
    Perú: [
      "BCP",
      "Scotiabank",
      "Interbank",
    ],
    EEUU: [
      "Bank of America",
      "Chase",
      "Wells Fargo",
    ],
  };

function Directory() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedBank, setSelectedBank] = useState("");

  const userDirectory = [
    {
        accbsUser_id: 1,
        accbsUser_owner: "Juan Pérez",
        accbsUser_dni: "V12345678",
        accbsUser_bank: "Banco de Venezuela",
        accbsUser_phone: "04141234567",
        accbsUser_number: "1234567890",
        accbsUser_country: "Venezuela",
        accbsUser_status: "Activo",
        accbsUser_type: "Pago Movil",
        flag: Venezuela,
      },
      {
        accbsUser_id: 2,
        accbsUser_owner: "Pedro González",
        accbsUser_dni: "P98765432",
        accbsUser_bank: "Banco de Chile",
        accbsUser_phone: "561234567",
        accbsUser_number: "0987654321",
        accbsUser_country: "Chile",
        accbsUser_status: "Activo",
        accbsUser_type: "Cuenta Bancaria",
        flag: Chile,
      },
      {
        accbsUser_id: 3,
        accbsUser_owner: "María López",
        accbsUser_dni: "M12345678",
        accbsUser_bank: "Banco de Colombia",
        accbsUser_phone: "3001234567",
        accbsUser_number: "5678901234",
        accbsUser_country: "Colombia",
        accbsUser_status: "Inactivo",
        accbsUser_type: "Zelle",
        flag: Colombia,
      },
      {
        accbsUser_id: 4,
        accbsUser_owner: "Ana Torres",
        accbsUser_dni: "A98765432",
        accbsUser_bank: "Banco de Ecuador",
        accbsUser_phone: "0998765432",
        accbsUser_number: "1234567890",
        accbsUser_country: "Ecuador",
        accbsUser_status: "Activo",
        accbsUser_type: "Pago Movil",
        flag: Ecuador,
      },
      {
        accbsUser_id: 5,
        accbsUser_owner: "Luis Ramírez",
        accbsUser_dni: "L65432198",
        accbsUser_bank: "Banco de Panamá",
        accbsUser_phone: "612345678",
        accbsUser_number: "0987654321",
        accbsUser_country: "Panamá",
        accbsUser_status: "Activo",
        accbsUser_type: "Transferencia",
        flag: Panama,
      },
      {
        accbsUser_id: 6,
        accbsUser_owner: "Carla Gómez",
        accbsUser_dni: "C32165498",
        accbsUser_bank: "Banco de Perú",
        accbsUser_phone: "987654321",
        accbsUser_number: "1234567890",
        accbsUser_country: "Perú",
        accbsUser_status: "Inactivo",
        accbsUser_type: "Zelle",
        flag: Peru,
      },
      {
        accbsUser_id: 7,
        accbsUser_owner: "Javier Martínez",
        accbsUser_dni: "J45678912",
        accbsUser_bank: "Banco de EE. UU.",
        accbsUser_phone: "321654987",
        accbsUser_number: "0987654321",
        accbsUser_country: "EEUU",
        accbsUser_status: "Activo",
        accbsUser_type: "Transferencia",
        flag: EEUU,
      },
    ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openEditModal = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedBeneficiary(null);
    setIsEditModalOpen(false);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };


  return (
    <div className="directory">
      <header className="directory__header">
        <h1>Tus Beneficiarios</h1>
        <Button color="primary" onClick={toggleModal}>
          Nuevo beneficiario
        </Button>
      </header>

      <div className="directory__list">
        {userDirectory.map((beneficiary) => (
          <div className="beneficiary-card" key={beneficiary.accbsUser_id}>
            <img src={beneficiary.flag} alt={`${beneficiary.accbsUser_country} bandera`} className="country-flag" />
            <div className="beneficiary-info">
              <h3>{beneficiary.accbsUser_owner}</h3>
              <p>Cédula: {beneficiary.accbsUser_dni}</p>
              <p>Banco: {beneficiary.accbsUser_bank}</p>
              <p>Télefono: {beneficiary.accbsUser_phone}</p>
              <p>Tipo: {beneficiary.accbsUser_type}</p>
              <p>Status: {beneficiary.accbsUser_status}</p>
            </div>
            <div className="action-buttons">
              <Button className="btn-edit" onClick={() => openEditModal(beneficiary)}>
                <FaEdit /> Editar
              </Button>
             
              <Button
                className={beneficiary.accbsUser_status === "Activo" ? "btn-deactivate" : "btn-activate"}
                onClick={() => {/* Logic to activate/deactivate */}}>
                {beneficiary.accbsUser_status === "Activo" ? <FaUserTimes /> : <FaUserCheck />}
                {beneficiary.accbsUser_status === "Activo" ? " Desactivar" : " Activar"}
              </Button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal para agregar nuevo beneficiario */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Agregar Nuevo Beneficiario</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="country">País</Label>
              <Input type="select" name="country" id="country" onChange={handleCountryChange} required>
                <option value="">Selecciona un país...</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Chile">Chile</option>
                <option value="Colombia">Colombia</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Panamá">Panamá</option>
                <option value="Perú">Perú</option>
                <option value="EEUU">EEUU</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="bank">Banco</Label>
              <Input type="select" name="bank" id="bank" value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)} required>
                <option value="">Selecciona un banco...</option>
                {selectedCountry && banksByCountry[selectedCountry]?.map((bank, index) => (
                  <option key={index} value={bank}>{bank}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="name">Nombre y Apellido</Label>
              <Input type="text" name="name" id="name" placeholder="Ingresa nombre" required />
            </FormGroup>
            <FormGroup>
              <Label for="dni">Cédula</Label>
              <Input type="text" name="dni" id="dni" placeholder="Ingresa cédula" required />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Teléfono</Label>
              <Input type="text" name="phone" id="phone" placeholder="Ingresa teléfono" required />
            </FormGroup>
            <FormGroup>
              <Label for="accountType">Tipo de Cuenta</Label>
              <Input type="select" name="accountType" id="accountType" required>
                <option value="">Selecciona...</option>
                <option value="Pago Movil">Pago Móvil</option>
                <option value="Cuenta Bancaria">Cuenta Bancaria</option>
                <option value="Zelle">Zelle</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary">Guardar Beneficiario</Button>
          <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
        </ModalFooter>
      </Modal>

      {/* Modal para editar beneficiario */}
      {isEditModalOpen && selectedBeneficiary && (
        <Modal isOpen={isEditModalOpen} toggle={closeEditModal}>
          <ModalHeader toggle={closeEditModal}>Editar Beneficiario</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="name">Nombre y Apellido</Label>
                <Input type="text" defaultValue={selectedBeneficiary.accbsUser_owner} required />
              </FormGroup>
              <FormGroup>
                <Label for="dni">Cédula</Label>
                <Input type="text" defaultValue={selectedBeneficiary.accbsUser_dni} required />
              </FormGroup>
              <FormGroup>
                <Label for="bank">Banco</Label>
                <Input type="text" defaultValue={selectedBeneficiary.accbsUser_bank} required />
              </FormGroup>
              <FormGroup>
                <Label for="phone">Teléfono</Label>
                <Input type="text" defaultValue={selectedBeneficiary.accbsUser_phone} required />
              </FormGroup>
              <FormGroup>
                <Label for="accountType">Tipo de Cuenta</Label>
                <Input type="select" defaultValue={selectedBeneficiary.accbsUser_type} required>
                  <option value="Pago Movil">Pago Móvil</option>
                  <option value="Cuenta Bancaria">Cuenta Bancaria</option>
                  <option value="Zelle">Zelle</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Guardar Cambios</Button>
            <Button color="secondary" onClick={closeEditModal}>Cancelar</Button>
          </ModalFooter>
        </Modal>
        )}
    </div>
  );
}

export { Directory };
