import React, { useState } from "react";
import { Button } from "reactstrap";
import { CSSTransition } from "react-transition-group";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import chile from "../Assets/Images/Chile.png";
import colombia from "../Assets/Images/Colombia.png";
import ecuador from "../Assets/Images/Ecuador.png";
import panama from "../Assets/Images/Panama.png";
import peru from "../Assets/Images/Peru.png";
import usa from "../Assets/Images/EEUU.png";
import venezuelaFlag from "../Assets/Images/Venezuela.png";

function SendMoney() {
    const [step, setStep] = useState(1);
    const [payment, setPayment] = useState("");
    const [withdrawalMethod, setWithdrawalMethod] = useState("");
    const [amount, setAmount] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [porcent, setPorcent] = useState(null);
    const [isCashMethodSelected, setIsCashMethodSelected] = useState(false);
    const [amountToReceive, setAmountToReceive] = useState("");
    const [receiverName, setReceiverName] = useState("");
  const [receiverDni, setReceiverDni] = useState("");
  const [phone, setPhone] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [loading, setLoading] = useState(false);
  const [userDirectory] = useState([
    {
      accbsUser_id: "1",
      accbsUser_owner: "Juan Pérez",
      accbsUser_dni: "12345678",
      accbsUser_bank: "Banco Nacional",
      accbsUser_type: "Cuenta Bancaria",
      accbsUser_number: "1234-5678-9101",
      accbsUser_country: "Venezuela",
      accbsUser_status: "Activo",
    },
    {
      accbsUser_id: "2",
      accbsUser_owner: "Ana Gómez",
      accbsUser_dni: "87654321",
      accbsUser_bank: "Banco Internacional",
      accbsUser_type: "Pago Movil",
      accbsUser_phone: "+58 424-567-8910",
      accbsUser_country: "Argentina",
      accbsUser_status: "Activo",
    },
  ]); // Datos estáticos de ejemplo

  const handleBack = () => {
    setStep(step - 1);
  };

  const openModal = () => {
    // Lógica para abrir un modal
    alert("Abriendo modal para agregar un nuevo beneficiario...");
  };

  const handleBeneficiarySelect = (beneficiary) => {
    setReceiverName(beneficiary.accbsUser_owner);
    setReceiverDni(beneficiary.accbsUser_dni);
    setStep(3); // Avanzar al Paso 3 automáticamente
    console.log(setAmountToReceive, setSelectedBeneficiary, handleNextStep, handlePreviousStep, resetSendMoney, user, porcents, currencyPrice, handleSubmitSend);
  };


  

    const [errors, setErrors] = useState({});
  
    const handleCurrencyChange = (e) => {
      setPayment(e.target.value);
      setWithdrawalMethod("");
      setAmount("");
      setSelectedCurrency("");
      setPorcent(null);
      setErrors({});
    };
  
    const handleWithdrawalMethodChange = (e) => {
        const method = e.target.value;
        setWithdrawalMethod(method);
    
        if (method === "efectivo") {
          setIsCashMethodSelected(true);
          setSelectedCurrency(""); // Limpiar moneda si cambia a efectivo
          setAmount(""); // Reiniciar monto si cambia a efectivo
        } else {
          setIsCashMethodSelected(false);
        }
      };
  
    const handleamountChange = (e) => {
      setAmount(e.target.value);
    };
  
    const fetchDataPorcentId = (id) => {
      const selectedPorcent = porcents.find((por) => por.por_id === id);
      setPorcent(selectedPorcent);
    };
  
    const getPercentage = () => (porcent ? porcent.por_percentage : 0);
  
    const calculateValue = (amount, payment, porcent) => {
      if (!amount || !porcent) return 0;
      const percentage = getPercentage();
      return (amount - amount * (percentage / 100)).toFixed(2);
    };

  const handleNextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const resetSendMoney = () => {
    setStep(1);
  };

  const user = {
    use_amountEur: 1500.75, // Saldo en Euros
    use_amountUsd: 2000.50, // Saldo en Dólares
  };
  
  const porcents = [
    {
      por_id: "1",
      por_stateLocation: "Madrid",
      por_percentage: 5,
      por_status: "Oficina",
      por_comment: "Recoge tu dinero en la oficina central.",
      por_deliveryPrice: 15.00,
    },
    {
      por_id: "2",
      por_stateLocation: "Barcelona",
      por_percentage: 3,
      por_status: "Obligatorio",
      por_deliveryPrice: 10.00,
    },
    {
      por_id: "3",
      por_stateLocation: "Valencia",
      por_percentage: 7,
      por_status: "Desactivado",
    },
  ];

  
  const currencyPrice = [
    {
      cur_EurToBs: 25.50,
      cur_EurToArg_Pes: 270.30,
      cur_EurToCol_Pes: 4500.75,
      cur_EurToPes_Ch: 900.10,
      cur_EurToPes_Mex: 22.35,
      cur_EurToSol_Pe: 4.15,
      cur_EurToBra_Rea: 5.20,
      cur_EurToUsd_Ecu: 1.10,
      cur_EurToUsd_Pa: 1.10,
      cur_UsdToBs: 24.30,
      cur_UsdToArg_Pes: 260.45,
      cur_UsdToCol_Pes: 4300.50,
      cur_UsdToPes_Ch: 880.50,
      cur_UsdToPes_Mex: 21.75,
      cur_UsdToSol_Pe: 3.90,
      cur_UsdToBra_Rea: 4.90,
      cur_UsdToUsd_Ecu: 1.00,
      cur_UsdToUsd_Pa: 1.00,
    },
  ];


  const handleSubmitSend = () => {
    console.log("Enviando datos...");
  
    setLoading(true); // Activa el estado de carga
    setTimeout(() => {
      console.log("Datos enviados con éxito!");
      setLoading(false); // Desactiva el estado de carga
      setStep(4); // Avanza al paso 4 de confirmación
    }, 2000); // 2 segundos de retraso simulado
  };
  
  

  return (
    <div className="recharge-container">
      <h2 className="recharge-title">Enviar Dinero</h2>

      {/* Step Indicator */}
      <div className="step-indicator">
        {[1, 2, 3, 4].map((stepNumber) => (
          <div
            key={stepNumber}
            className={`step-item ${step >= stepNumber ? (step > stepNumber ? "completed" : "active") : ""}`}
            onClick={() => step > stepNumber && setStep(stepNumber)}
          >
            {step > stepNumber ? <FaCheck className="step-icon" /> : <div className="step-number">{stepNumber}</div>}
            <p className="step-label">{["Paso 1", "Paso 2", "Paso 3", "Confirmación"][stepNumber - 1]}</p>
          </div>
        ))}
      </div>

      {/* Paso 1 */}
      <CSSTransition in={step === 1} timeout={300} classNames="fade" unmountOnExit>
  <div className="step-content">
    <div className="balances-cards">
      <div className="balance-item">
        <h3>Saldo en Euros</h3>
        <p>€{user.use_amountEur ? user.use_amountEur.toFixed(2) : 0}</p>
      </div>
      <div className="balance-item">
        <h3>Saldo en Dólares</h3>
        <p>${user.use_amountUsd ? user.use_amountUsd.toFixed(2) : 0}</p>
      </div>
    </div>

    <div className="form-container">
      <div className="form-group">
        <label htmlFor="currency">Moneda a enviar</label>
        <select
          id="currency"
          value={payment}
          onChange={handleCurrencyChange}
          className="custom-select"
        >
          <option>Seleccione...</option>
          <option value="EUR">Euros (€)</option>
          <option value="USD">Dólares ($)</option>
          <option value="GBP">Libras (£)</option>
        </select>
      </div>

      {payment && (
        <div className="form-group">
          <label htmlFor="withdrawal-method">Método de retiro</label>
          <select
            id="withdrawal-method"
            value={withdrawalMethod}
            onChange={handleWithdrawalMethodChange}
            className="custom-select"
          >
            <option value="">Seleccione...</option>
            <option value="transferencia">Transferencia</option>
            <option value="efectivo">Efectivo</option>
          </select>
        </div>
      )}

{isCashMethodSelected && (
        <>
          <div className="form-group">
            <label htmlFor="state-location">Ubicación</label>
            <select
              id="state-location"
              defaultValue={withdrawalMethod}
              onChange={(e) => fetchDataPorcentId(e.target.value)}
              className="custom-select"
            >
              <option value="">Seleccione una ubicación</option>
              {porcents.map((por) => {
                if (por.por_status === "Desactivado") {
                  return null;
                }
                return (
                  <option key={por.por_id} value={por.por_id}>
                    {por.por_stateLocation}
                  </option>
                );
              })}
            </select>
          </div>

          {porcent && (
            <>
              <div className="form-group">
                <label>Porcentaje</label>
                <input
                  type="text"
                  value={`${getPercentage()}%`}
                  disabled
                  className="custom-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="status">Estado</label>
                <input
                  type="text"
                  id="status"
                  value={porcent.por_status}
                  disabled
                  className="custom-input"
                />

                {porcent.por_status === "Oficina" && (
                  <div className="form-group">
                    <label htmlFor="comment">Comentario</label>
                    <input
                      type="text"
                      id="comment"
                      value={porcent.por_comment}
                      disabled
                      className="custom-input"
                    />
                  </div>
                )}

                {porcent.por_status === "Obligatorio" && (
                  <div className="form-group">
                    <label htmlFor="delivery-price">Precio de entrega</label>
                    <input
                      type="text"
                      id="delivery-price"
                      value={`Precio de entrega: ${porcent.por_deliveryPrice}`}
                      disabled
                      className="custom-input"
                    />
                  </div>
                )}

                {porcent.por_status === "Desactivado" && (
                  <div className="form-group">
                    <label htmlFor="no-cash">Mensaje</label>
                    <input
                      type="text"
                      id="no-cash"
                      value="No hay efectivo para esta ubicación por los momentos."
                      disabled
                      className="custom-input"
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}


      {withdrawalMethod === "transferencia" && (
        <div className="form-group">
          <label htmlFor="currency-receive">¿Qué moneda quieres recibir?</label>
          <select
            id="currency-receive"
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            className="custom-select"
          >
            <option value="">Seleccione una moneda</option>
            <option value="BS">Bolívares</option>
            <option value="USD">Dólares (USD)</option>
            <option value="ARS">Pesos Argentinos</option>
            <option value="COP">Pesos Colombianos (COP)</option>
            <option value="CLP">Pesos Chilenos (CLP)</option>
            <option value="MXN">Pesos Mexicanos (MXN)</option>
            <option value="PEN">Soles (PEN)</option>
            <option value="BRL">Reales Brasileños (BRL)</option>
            <option value="USD-EC">Dólar Ecuatoriano</option>
            <option value="USD-PA">Dólar Panameño</option>
          </select>
        </div>
      )}

      {withdrawalMethod === "efectivo" && (

        <>
        
          <div className="form-group">
            <label htmlFor="amount-send">Monto USD a recibir</label>
            <input
              type="number"
              id="amount-send"
              value={amount}
              onChange={handleamountChange}
              placeholder="Ingrese monto"
              className="custom-input"
            />
            <small>
              Saldo disponible:{" "}
              {payment === "EUR"
                ? `€${user.use_amountEur}`
                : `$${user.use_amountUsd}`}
            </small>
            {errors.amount && <span className="error">{errors.amount}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="amount-receive">
              Monto {payment === "EUR" ? "EUR" : "USD"} a debitar
            </label>
            <input
              type="number"
              id="amount-receive"
              value={calculateValue(amount, payment, porcent)}
              readOnly
              placeholder="Calculando..."
              className="custom-input"
            />
          </div>
        </>
      )}

      {selectedCurrency && (
        <>
          <div className="form-group">
            <label htmlFor="amount-send">
              {selectedCurrency === "USD" ? "Monto a debitar" : "Monto a enviar"}
            </label>
            {errors.amount && <span className="error">{errors.amount}</span>}
            <input
              type="number"
              id="amount-send"
              value={amount}
              onChange={handleamountChange}
              placeholder="Ingrese monto"
              className="custom-input"
            />
            <small>
              Saldo disponible:{" "}
              {payment === "EUR"
                ? `€${user.use_amountEur}`
                : `$${user.use_amountUsd}`}
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="amount-receive">
              {selectedCurrency === "USD" ? "Monto USD a recibir" : "Monto a recibir"}
            </label>
            <input
              type="number"
              id="amount-receive"
              value={amountToReceive}
              readOnly
              placeholder="Calculando..."
              className="custom-input"
            />
          </div>
        </>
      )}

      {amount && (
        <div className="exchange-rate-box">
          <h4>Tasa de cambio</h4>
          {currencyPrice.map((coin) => {
            if (payment === "EUR" && selectedCurrency === "BS") {
              return (
                <p key={coin.cur_EurToBs}>{`1 ${payment} = ${coin.cur_EurToBs} Bs.`}</p>
              );
            } else if (payment === "EUR" && selectedCurrency === "ARS") {
              return (
                <p key={coin.cur_EurToArg_Pes}>
                  {`1 ${payment} = ${coin.cur_EurToArg_Pes} ARS.`}
                </p>
              );
            } else if (payment === "EUR" && selectedCurrency === "COP") {
              return (
                <p key={coin.cur_EurToCol_Pes}>
                  {`1 ${payment} = ${coin.cur_EurToCol_Pes} COP.`}
                </p>
              );
            } else if (payment === "EUR" && selectedCurrency === "CLP") {
              return (
                <p key={coin.cur_EurToPes_Ch}>
                  {`1 ${payment} = ${coin.cur_EurToPes_Ch} CLP.`}
                </p>
              );
            } else if (payment === "EUR" && selectedCurrency === "MXN") {
              return (
                <p key={coin.cur_EurToPes_Mex}>
                  {`1 ${payment} = ${coin.cur_EurToPes_Mex} MXN.`}
                </p>
              );
            } else if (payment === "EUR" && selectedCurrency === "PEN") {
              return (
                <p key={coin.cur_EurToSol_Pe}>
                  {`1 ${payment} = ${coin.cur_EurToSol_Pe} PEN.`}
                </p>
              );
            } else if (payment === "EUR" && selectedCurrency === "BRL") {
              return (
                <p key={coin.cur_EurToBra_Rea}>
                  {`1 ${payment} = ${coin.cur_EurToBra_Rea} BRL.`}
                </p>
              );
            } else if (payment === "EUR" && selectedCurrency === "USD-EC") {
              return (
                <p key={coin.cur_EurToUsd_Ecu}>
                  {`1 ${payment} = ${coin.cur_EurToUsd_Ecu} USD.`}
                </p>
              );
            } else if (payment === "EUR" && selectedCurrency === "USD-PA") {
              return (
                <p key={coin.cur_EurToUsd_Pa}>
                  {`1 ${payment} = ${coin.cur_EurToUsd_Pa} USD.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "BS") {
              return (
                <p key={coin.cur_UsdToBs}>{`1 ${payment} = ${coin.cur_UsdToBs} Bs.`}</p>
              );
            } else if (payment === "USD" && selectedCurrency === "ARS") {
              return (
                <p key={coin.cur_UsdToArg_Pes}>
                  {`1 ${payment} = ${coin.cur_UsdToArg_Pes} ARS.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "COP") {
              return (
                <p key={coin.cur_UsdToCol_Pes}>
                  {`1 ${payment} = ${coin.cur_UsdToCol_Pes} COP.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "CLP") {
              return (
                <p key={coin.cur_UsdToPes_Ch}>
                  {`1 ${payment} = ${coin.cur_UsdToPes_Ch} CLP.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "MXN") {
              return (
                <p key={coin.cur_UsdToMex}>
                  {`1 ${payment} = ${coin.cur_UsdToPes_Mex} MXN.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "PEN") {
              return (
                <p key={coin.cur_UsdToSol_Pe}>
                  {`1 ${payment} = ${coin.cur_UsdToSol_Pe} PEN.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "BRL") {
              return (
                <p key={coin.cur_UsdToBra_Rea}>
                  {`1 ${payment} = ${coin.cur_UsdToBra_Rea} BRL.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "USD-EC") {
              return (
                <p key={coin.cur_UsdToUsd_Ecu}>
                  {`1 ${payment} = ${coin.cur_UsdToUsd_Ecu} USD.`}
                </p>
              );
            } else if (payment === "USD" && selectedCurrency === "USD-PA") {
              return (
                <p key={coin.cur_UsdToUsd_Pa}>
                  {`1 ${payment} = ${coin.cur_UsdToUsd_Pa} USD.`}
                </p>
              );
            }
            return null;
          })}
        </div>
      )}

      {/* Botón de continuar */}
      {amount && (
        <div className="form-actions">
          <button
            className="continue-button"
            onClick={() => setStep(step + 1)}
            disabled={!!errors.amount}
          >
            {errors.amount ? "Corrige el error para continuar" : "Continúa"}
          </button>
        </div>
      )}
    </div>
  </div>
</CSSTransition>



      {/* Paso 2 */}
      <CSSTransition in={step === 2} timeout={300} classNames="fade" unmountOnExit>
  <div className="beneficiary-step">
    <h2>Selecciona un beneficiario</h2>

    {withdrawalMethod === "efectivo" ? (
      <>
        <div className="form-container">
          <div className="form-group">
            <label htmlFor="receiver-name">Nombre de quien recibe</label>
            <input
              type="text"
              id="receiver-name"
              value={receiverName}
              onChange={(e) => setReceiverName(e.target.value)}
              placeholder="Ingrese el nombre del receptor"
              className="custom-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="receiver-dni">Cédula de quien recibe</label>
            <input
              type="text"
              id="receiver-dni"
              value={receiverDni}
              onChange={(e) => setReceiverDni(e.target.value)}
              placeholder="Ingrese la cédula del receptor"
              className="custom-input"
            />
          </div>

          {porcent && porcent.por_status === "Obligatorio" && (
            <>
              <div className="form-group">
                <label htmlFor="phone">Número de contacto de quien recibe</label>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Ingrese número de contacto"
                  className="custom-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="delivery-address">Dirección de entrega</label>
                <input
                  type="text"
                  id="delivery-address"
                  value={deliveryAddress}
                  onChange={(e) => setDeliveryAddress(e.target.value)}
                  placeholder="Ingrese la dirección de entrega"
                  className="custom-input"
                />
              </div>
            </>
          )}

          {porcent && porcent.por_status === "Oficina" && (
            <div className="form-group">
              <label htmlFor="office-address">Dirección de la oficina</label>
              <input
                type="text"
                id="office-address"
                value={porcent.por_comment}
                disabled
                className="custom-input"
              />
            </div>
          )}

          <div className="form-actions">
            <button className="back-button" onClick={handleBack}>
              Volver
            </button>
            <button
              className="continue-button"
              disabled={
                !receiverName || !receiverDni || !phone || !deliveryAddress
              }
              onClick={() => setStep(3)}
            >
              Continúa
            </button>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="beneficiaries-list">
          {userDirectory.length === 0 ? (
            <div className="beneficiaries-list">
              <p style={{ color: "#003366" }}>
                <strong>
                  No tienes beneficiarios agregados. Agrega uno para continuar.
                </strong>
              </p>
              <Link to="/Directory">
                <div className="form-actions">
                  <button className="continue-button">
                    Agrega a tu beneficiario
                  </button>
                </div>
              </Link>
            </div>
          ) : (
            <>
              <div className="beneficiaries-list">
                {userDirectory
                  .filter(
                    (beneficiario) =>
                      beneficiario.accbsUser_status === "Activo" &&
                      (selectedCurrency === "BS"
                        ? beneficiario.accbsUser_country === "Venezuela"
                        : selectedCurrency === "ARS"
                        ? beneficiario.accbsUser_country === "Argentina"
                        : selectedCurrency === "USD"
                        ? beneficiario.accbsUser_country === "Estados Unidos"
                        : selectedCurrency === "COP"
                        ? beneficiario.accbsUser_country === "Colombia"
                        : selectedCurrency === "CLP"
                        ? beneficiario.accbsUser_country === "Chile"
                        : selectedCurrency === "MXN"
                        ? beneficiario.accbsUser_country === "Mexico"
                        : selectedCurrency === "PEN"
                        ? beneficiario.accbsUser_country === "Peru"
                        : selectedCurrency === "BRL"
                        ? beneficiario.accbsUser_country === "Brasil"
                        : selectedCurrency === "USD-EC"
                        ? beneficiario.accbsUser_country === "Ecuador"
                        : selectedCurrency === "USD-PA"
                        ? beneficiario.accbsUser_country === "Panama"
                        : null)
                  )
                  .map((beneficiary) => (
                    <div
                      className="beneficiary-card"
                      key={beneficiary.accbsUser_id}
                      onClick={() => handleBeneficiarySelect(beneficiary)}
                    >
                      <img
                        src={
                          beneficiary.accbsUser_country === "Venezuela"
                            ? venezuelaFlag
                            : beneficiary.accbsUser_country === "Estados Unidos"
                            ? usa
                            : beneficiary.accbsUser_country === "Colombia"
                            ? colombia
                            : beneficiary.accbsUser_country === "Chile"
                            ? chile
                            : beneficiary.accbsUser_country === "Ecuador"
                            ? ecuador
                            : beneficiary.accbsUser_country === "Peru"
                            ? peru
                            : beneficiary.accbsUser_country === "Panama"
                            ? panama
                            : null
                        }
                        alt={beneficiary.accbsUser_country}
                        className="flag-icon"
                      />
                      <div className="beneficiary-info">
                        <h3>{beneficiary.accbsUser_owner}</h3>
                        <p>Cédula: {beneficiary.accbsUser_dni}</p>
                        <p>Banco: {beneficiary.accbsUser_bank}</p>
                        <p>
                          {beneficiary.accbsUser_type === "Pago Movil"
                            ? "Teléfono: " + beneficiary.accbsUser_phone
                            : "Cuenta: " + beneficiary.accbsUser_number}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="form-actions">
                <button className="back-button" onClick={handleBack}>
                  Volver
                </button>
                <button
                  className="add-beneficiary-button"
                  onClick={openModal}
                >
                  Nuevo Beneficiario
                </button>
              </div>
            </>
          )}
        </div>
      </>
    )}
  </div>
</CSSTransition>


      {/* Paso 3 */}
      <CSSTransition in={step === 3} timeout={300} classNames="fade" unmountOnExit>
      <div className="confirmation-step">
        <h2>Confirma los detalles</h2>
        <p>
          <strong>Moneda a enviar:</strong> {payment}
        </p>
        <p>
          <strong>Monto a Descontar:</strong>{" "}
          {withdrawalMethod === "efectivo"
            ? calculateValue(amount, payment, porcent)
            : amount}{" "}
          {payment}
        </p>
        <p>
          <strong>
            {withdrawalMethod === "efectivo"
              ? "Monto USD a recibir"
              : "Monto a recibir"}:
          </strong>{" "}
          {withdrawalMethod === "efectivo"
            ? amount + " USD"
            : amountToReceive + " " + selectedCurrency}
        </p>
        <h3>Beneficiario seleccionado</h3>
        <p>
          <strong>Nombre:</strong>{" "}
          {selectedBeneficiary
            ? selectedBeneficiary.accbsUser_owner
            : receiverName}
        </p>
        <p>
          <strong>Cédula:</strong>{" "}
          {selectedBeneficiary
            ? selectedBeneficiary.accbsUser_dni
            : receiverDni}
        </p>
        <p>
          {selectedBeneficiary ? (
            <strong>Banco: {selectedBeneficiary.accbsUser_bank} </strong>
          ) : (
            <strong>Dirección de entrega: {deliveryAddress}</strong>
          )}
        </p>
        {selectedBeneficiary ? (
          <p>
            <strong>
              {selectedBeneficiary.accbsUser_type === "Pago Movil"
                ? "Teléfono: " + selectedBeneficiary.accbsUser_phone
                : "Cuenta: " + selectedBeneficiary.accbsUser_number}
            </strong>
          </p>
        ) : null}

        <div className="form-actions">
          <button className="back-button" onClick={handleBack}>
            Volver
          </button>
          <button
            className="confirm-button"
            disabled={loading}
            onClick={() => {
              handleSubmitSend();
              setStep(4);
            }}
          >
            {loading ? "Enviando..." : "Confirmar y Enviar"}
          </button>
        </div>
      </div>
    </CSSTransition>

      {/* Confirmación */}
      <CSSTransition in={step === 4} timeout={300} classNames="fade" unmountOnExit>
        <div className="step-content">
          {/* Contenido de confirmación */}
          <h3 className="step-header">¡Envío Completo!</h3>
          <p className="confirmation-message">Tu dinero ha sido enviado con éxito.</p>
          <div className="form-navigation">
            <Button color="primary" onClick={resetSendMoney} className="nav-btn">
              Realizar otro envío
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export { SendMoney };
