import React, { useState } from "react";
import { Button, Input, FormGroup, Label, Alert } from "reactstrap";
import { CSSTransition } from "react-transition-group";
import {
  FaCheck,
  FaCheckCircle,
  FaDollarSign,
  FaEuroSign,
  FaArrowLeft,
  FaArrowRight,
  FaRegCreditCard,
  FaMobileAlt,
} from "react-icons/fa";

function Recharge() {
  const [step, setStep] = useState(1);
  const [payment, setPayment] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [bankOptionPay, setBankOptionPay] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const [cashPhone, setCashPhone] = useState("");
  const [cash, setCash] = useState("");
  const [mov_img, setMov_img] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCurrencyChange = (currency) => {
    setPayment(currency);
    setStep(2);
  };

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
  };

  const handleBankChange = (e) => {
    setBankOptionPay(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setMov_img(file);
    setImageUrl(URL.createObjectURL(file));
  };

  const validateStep = () => {
    if (step === 2) {
      if (!payment) {
        setErrorMessage("Por favor, selecciona una moneda.");
        return false;
      }
      if (!selectedMethod) {
        setErrorMessage("Por favor, selecciona un método de recarga.");
        return false;
      }
      if (selectedMethod === "transferencia" && !bankOptionPay) {
        setErrorMessage("Por favor, selecciona un banco.");
        return false;
      }
      if ((selectedMethod === "efectivoBBVA" || selectedMethod === "efectivoSantander") && (!cashPhone || !cash)) {
        setErrorMessage("Por favor, completa todos los campos de Efectivo Móvil.");
        return false;
      }
      if (!sendAmount || sendAmount < 20) {
        setErrorMessage("El monto debe ser superior a 20.");
        return false;
      }
    }

    if (step === 3 && !mov_img) {
      setErrorMessage("Por favor, adjunta el comprobante de pago.");
      return false;
    }

    setErrorMessage(""); // Limpiar el mensaje de error si pasa todas las validaciones
    return true;
  };

  const handleNextStep = () => {
    if (validateStep()) {
      if (step < 4) {
        setStep(step + 1);
      }
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const resetRecharge = () => {
    setStep(1);
    setPayment("");
    setSelectedMethod("");
    setBankOptionPay("");
    setSendAmount("");
    setCash("");
    setCashPhone("");
    setMov_img(null);
    setErrorMessage("");
  };

  return (
    <div className="recharge-container">
      <h2 className="recharge-title">Recargar Saldo</h2>

      {/* Step Indicator */}
      <div className="step-indicator">
        {[1, 2, 3, 4].map((stepNumber) => (
          <div
            key={stepNumber}
            className={`step-item ${step >= stepNumber ? (step > stepNumber ? "completed" : "active") : ""}`}
            onClick={() => step > stepNumber && setStep(stepNumber)}
          >
            {step > stepNumber ? <FaCheck className="step-icon" /> : <div className="step-number">{stepNumber}</div>}
            <p className="step-label">{["Moneda", "Método", "Comprobante", "Confirmación"][stepNumber - 1]}</p>
          </div>
        ))}
      </div>

      {/* Mensaje de Error */}
      {errorMessage && <Alert color="danger" className="error-alert">{errorMessage}</Alert>}

      {/* Paso 1: Selección de moneda */}
      <CSSTransition in={step === 1} timeout={300} classNames="fade" unmountOnExit>
        <div className="step-content">
          <h3 className="step-header">Selecciona la moneda</h3>
          <div className="currency-options">
            <Button
              color="light"
              className={`currency-btn ${payment === "EUR" ? "selected" : ""}`}
              onClick={() => handleCurrencyChange("EUR")}
            >
              <FaEuroSign className="currency-icon" /> Euros (€)
            </Button>
            <Button
              color="light"
              className={`currency-btn ${payment === "USD" ? "selected" : ""}`}
              onClick={() => handleCurrencyChange("USD")}
            >
              <FaDollarSign className="currency-icon" /> Dólares ($)
            </Button>
          </div>
        </div>
      </CSSTransition>

      {/* Paso 2: Selección de método */}
      <CSSTransition in={step === 2} timeout={300} classNames="fade" unmountOnExit>
        <div className="step-content">
          <h3 className="step-header">Elige tu método de recarga</h3>
          <FormGroup>
            <Label for="methodSelect" className="form-label">Método de Recarga</Label>
            <Input type="select" id="methodSelect" value={selectedMethod} onChange={handleMethodChange} className="custom-select">
              <option value="">Selecciona el método</option>
              <option value="transferencia"><FaRegCreditCard /> Transferencia Bancaria</option>
              <option value="efectivoBBVA"><FaMobileAlt /> Efectivo Móvil BBVA</option>
              <option value="efectivoSantander"><FaMobileAlt /> Efectivo Por Cajero Santander</option>
            </Input>
          </FormGroup>

          {selectedMethod === "transferencia" && (
            <FormGroup>
              <Label for="bankSelect" className="form-label">Selecciona el Banco</Label>
              <Input type="select" id="bankSelect" value={bankOptionPay} onChange={handleBankChange} className="custom-select">
                <option value="">Selecciona un banco</option>
                <option value="Bank1">Bank 1</option>
                <option value="Bank2">Bank 2</option>
              </Input>
            </FormGroup>
          )}

          {selectedMethod.startsWith("efectivo") && (
            <div className="efectivo-details">
              <FormGroup>
                <Label for="phoneInput" className="form-label">Teléfono</Label>
                <Input
                  type="text"
                  id="phoneInput"
                  placeholder="Número de teléfono"
                  value={cashPhone}
                  onChange={(e) => setCashPhone(e.target.value)}
                  className="custom-input"
                />
              </FormGroup>
              <FormGroup>
                <Label for="codeInput" className="form-label">Código de Transacción</Label>
                <Input
                  type="text"
                  id="codeInput"
                  placeholder="Código"
                  value={cash}
                  onChange={(e) => setCash(e.target.value)}
                  className="custom-input"
                />
              </FormGroup>
            </div>
          )}

          <FormGroup>
            <Label for="amountInput" className="form-label">Monto</Label>
            <Input
              type="number"
              id="amountInput"
              placeholder="Monto a transferir (debe ser superior a 20)"
              value={sendAmount}
              onChange={(e) => setSendAmount(e.target.value)}
              className="custom-input"
            />
          </FormGroup>

          <div className="form-navigation">
            <Button color="secondary" onClick={handlePreviousStep} className="nav-btn">
              <FaArrowLeft /> Volver
            </Button>
            <Button color="primary" onClick={handleNextStep} className="nav-btn">
              Continuar <FaArrowRight />
            </Button>
          </div>
        </div>
      </CSSTransition>

      {/* Paso 3: Adjuntar comprobante */}
      <CSSTransition in={step === 3} timeout={300} classNames="fade" unmountOnExit>
        <div className="step-content">
          <h3 className="step-header">Sube tu comprobante</h3>
          <Input type="file" onChange={handleFileChange} className="file-input" />
          {mov_img && <img src={imageUrl} alt="Comprobante" className="preview-image" />}

          <div className="form-navigation">
            <Button color="secondary" onClick={handlePreviousStep} className="nav-btn">
              <FaArrowLeft /> Volver
            </Button>
            <Button color="success" onClick={handleNextStep} className="nav-btn">
              Confirmar <FaArrowRight />
            </Button>
          </div>
        </div>
      </CSSTransition>

      {/* Paso 4: Confirmación */}
      <CSSTransition in={step === 4} timeout={300} classNames="fade" unmountOnExit>
        <div className="step-content">
          <h3 className="step-header">¡Recarga Completa!</h3>
          <FaCheckCircle size={60} color="#28a745" />
          <p className="confirmation-message">Tu recarga ha sido procesada exitosamente.</p>
          <div className="form-navigation">
            <Button color="primary" onClick={resetRecharge} className="nav-btn">
              Realizar otra recarga
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export { Recharge };
