import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaExchangeAlt, FaSpinner, FaExclamationTriangle } from 'react-icons/fa';
import { useDataContext } from "../Context/dataContext";
import flagEurope from '../Assets/Images/union-europea.png';
import flagVenezuela from '../Assets/Images/venezuelaflag.png';
import { Link } from 'react-router-dom';


function CurrencyCalculator() {
  const { infoTkn, url } = useDataContext();
  const [exchangeRate, setExchangeRate] = useState(0);
  const [eurAmount, setEurAmount] = useState('100');  // Mantener como string para permitir edición
  const [vefAmount, setVefAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(`${url}/CurrencyPrice`, {
          headers: {
            Authorization: `Bearer ${infoTkn}`,
          },
        });
        const data = response.data;

        const rateData = data.find((item) => item.cur_id === 1);
        if (rateData) {
          setExchangeRate(rateData.cur_EurToBs);
          setVefAmount((100 * rateData.cur_EurToBs).toFixed(2)); // Inicializamos con 100 EUR
        } else {
          setError('Tasa de cambio no encontrada para el cur_id 1');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate', error);
        setError('Error fetching exchange rate');
        setLoading(false);
      }
    };

    fetchExchangeRate();
  }, [infoTkn, url]);

  const handleEurChange = (e) => {
    const value = e.target.value;

    // Validar que el valor ingresado sea un número válido o vacío (permitir borrar)
    if (!isNaN(value) || value === '') {
      setEurAmount(value);
      const eurNumber = parseFloat(value);
      
      // Actualizar el monto en VEF solo si hay un valor válido
      if (!isNaN(eurNumber)) {
        setVefAmount((eurNumber * exchangeRate).toFixed(2));
      } else {
        setVefAmount(0); // Si el valor es vacío o no es número, poner VEF a 0
      }
    }
  };

  if (loading) return <div className="loading"><FaSpinner className="spinner" /> Cargando tasa de cambio...</div>;
  if (error) return <div className="error"><FaExclamationTriangle /> {error}</div>;

  return (
    <div className="currency-calculator">
      <div className="exchange-rate"> {/* Fondo verde aplicado aquí */}
        <FaExchangeAlt className="exchange-icon" />
        <p className="rate-text">Tasa de cambio: <span className="rate-value">1 EUR = {exchangeRate.toFixed(2)} VES</span></p>
      </div>

      <div className="calculator-horizontal">
        <div className="input-group">
          <label htmlFor="eur">
            <img src={flagEurope} alt="Bandera Europea" className="flag-icon" />
            Envías (EUR):
          </label>
          <input
            type="text" // Mantener como texto para permitir entradas no numéricas temporales
            id="eur"
            value={eurAmount} // Valor editable
            onChange={handleEurChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="vef">
            <img src={flagVenezuela} alt="Bandera de Venezuela" className="flag-icon" />
            Recibes (VEF):
          </label>
          <input
            type="text"
            id="vef"
            value={vefAmount} // Mostrar el valor en VEF calculado con 2 decimales
            readOnly
          />
        </div>
      </div>

      <div className="summary-section">
        <div className="summary-item">
          <p>Comisión</p>
          <span className="summary-value">+ 0,00 EUR</span>
        </div>

        <div className="summary-item">
          <p>Total a pagar</p>
          <span className="summary-value">{eurAmount !== '' ? `${eurAmount} EUR` : '0.00 EUR'}</span> {/* Mostrar '0.00' si está vacío */}
        </div>
      </div>

      <Link to='/Login'><button>Enviar ahora</button></Link>
    </div>
  );
}

export { CurrencyCalculator };
